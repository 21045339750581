import "src/styles/utils.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/utils.css.ts.vanilla.css\",\"source\":\"Ll8xbW04cDF5MCB7CiAgY3Vyc29yOiBwb2ludGVyOwp9Ci5fMW1tOHAxeTEgewogIHdpZHRoOiAxMDB2dzsKICBoZWlnaHQ6IDEwMHZoOwp9Ci5fMW1tOHAxeTIgewogIHotaW5kZXg6IC0xOwp9Ci5fMW1tOHAxeTMgewogIHotaW5kZXg6IDA7Cn0KLl8xbW04cDF5NCB7CiAgei1pbmRleDogNTA7Cn0KLl8xbW04cDF5NSB7CiAgei1pbmRleDogMTAwOwp9Ci5fMW1tOHAxeTYgewogIHotaW5kZXg6IDEwMDA7Cn0KLl8xbW04cDF5NyB7CiAgZGlzcGxheTogZmxleDsKfQouXzFtbThwMXk4IHsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5fMW1tOHAxeTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLl8xbW04cDF5YSB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xbW04cDF5YyB7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/mdx/DoDont.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/mdx/DoDont.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UwW7iMBC98xVzWQkOrhJYuhCkqn9SDfHgjOrYke0A7Yp/X+OELsmibenN4/Gb92beyA8vuWtVeH/M4PcEoLGeA1tTgCONgfe0ibcHlqEqIM+yH+dwa50kJxxKbn0Bq+b49za+ao7grWYJe3RTITyRFL5ETaK02jqhHL6JRZbNEgrLV+Vsa2SXLQYoqtEELnugNaJxXKN7S1C7J7fT9lBAxVKS2UxOk4dLO3lqp1e6tSHYuoDlTWlDEt+WJXk/G1Sbf7eaRKPIzUbSnoBrlUqOJlsRqypc4oGA+zGLkaO4jWrbkBwNtikgO5807UJ/jJNVHB/m887RnmjRG3whWgwM/1iD5Yj+Zzez//o72AoiI1A3FYq8X45PN+KmWcv7iF2M76S95epjYt1ZE4TndypgnnVj+phqjCHdXaF+JdT3+lx9DXtL7DpBFTYXmVc5/HfJrrLbcZv5qsM/1yQZwZdnHwGNhGnNRvR11uu4U7OEHYmIxTQdhWRHZf/z2MMmJVCzMoID1XG90isf0IVz8jQ5/QExrcVgugQAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var box = '_1rugtz6a _1mm8p1y8 _1mm8p1y7';
export var description = '_1rugtz6b seed-semantic-typography-body-l1-regular';
export var doCard = '_1rugtz61 _1rugtz60';
export var doDontLayout = '_1rugtz69 _1mm8p1yc _1mm8p1y7 _1mm8p1y9 _1mm8p1ya';
export var doIcon = '_1rugtz64 _1rugtz63 _1mm8p1y7 _1mm8p1y9 _1mm8p1ya';
export var doTitleText = '_1rugtz67 _1rugtz66 seed-semantic-typography-title2-bold';
export var dontCard = '_1rugtz62 _1rugtz60';
export var dontIcon = '_1rugtz65 _1rugtz63 _1mm8p1y7 _1mm8p1y9 _1mm8p1ya';
export var dontTitleText = '_1rugtz68 _1rugtz66 seed-semantic-typography-title2-bold';