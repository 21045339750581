import "src/styles/utils.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/utils.css.ts.vanilla.css\",\"source\":\"Ll8xbW04cDF5MCB7CiAgY3Vyc29yOiBwb2ludGVyOwp9Ci5fMW1tOHAxeTEgewogIHdpZHRoOiAxMDB2dzsKICBoZWlnaHQ6IDEwMHZoOwp9Ci5fMW1tOHAxeTIgewogIHotaW5kZXg6IC0xOwp9Ci5fMW1tOHAxeTMgewogIHotaW5kZXg6IDA7Cn0KLl8xbW04cDF5NCB7CiAgei1pbmRleDogNTA7Cn0KLl8xbW04cDF5NSB7CiAgei1pbmRleDogMTAwOwp9Ci5fMW1tOHAxeTYgewogIHotaW5kZXg6IDEwMDA7Cn0KLl8xbW04cDF5NyB7CiAgZGlzcGxheTogZmxleDsKfQouXzFtbThwMXk4IHsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5fMW1tOHAxeTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLl8xbW04cDF5YSB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLl8xbW04cDF5YyB7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKfQ==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/Header.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/Header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW7W6bMBT936ewKlVKpIIcvkLpn72KwYZ4BcwMSdNNlSbt115njzXtIWaMDY4habZISRRj3+tz7j3nJu7h4G2zLxB8uwOgYS3tKKsTkNMjwc/iUceaBMD+W0nyTn1NUfZScLavsZOxkvEEHBBfOU5LCBYfFao7mg1bToMawh1McrQvu3Uf/Upxt0vABsKHfrkjtNiJzCFsjvJGjmoNI2VHp90hzF4BdP0WENSSx9n1Ys/Te9aD57v3O3eguJEUp4yCC/CaIwjEGwJepGgFH4F6udBfG7GejP28bzuav4lL647UAnLboIw4KeleCakvUNPrCh0dfSSAim+FeEEFV7TvWL9uEMa0LhyuYsPrymKg9SVaTNumRG8JqFlNxgw545UAnqGSrKAbhSbL4DQuLVn2clVgKAML1PRUe7jjTnQmpSKZ9NWXimIcC5lwhOm+7bXwYJTT94ZDupx6fVKSs5qYauwMEh4qOkFMduxA+KCOi8LuuStVFxy9OaKtZhW2y2VXJLzolMSwHoNjy4CclKijBzODb2XQ6yuQxhLprcYdmmQJapRkv7dovDhJiVAPkQxH79zfG0eeLPYobVm578g0dzbKLbqa0Kom/MdajFcjeXUuYDkt/Up6Z/5vqnRZAMu0lL1VD837vavvD69q6zxqvWA5xXo0phqNCxNJQZZ2DwYRf6oIpkhMBy4GIUA1BqtK+E01axvFzXEti+OaPzdT+2LVvvfpiKeO6MsHGeg+z0B58wyByjCfPgsjbeMOVTHiQxU/zLVoln97Nr9xKNY0Fp09/WL4oSZm23vWqcgqgfFEQp3ibnL75PeNkMIlQflChkAom2KbvOl80/u/f33/8+Pn/bNVGtMf2vh62J+knSb2KUoJ4iLWCM76/KQS6VZEdicWep/avc9Los585IZNEEDLDlrr5v+DMBoV/WFGL7Yz+jdqP7Lj9TATaP4CNSPURTMKAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var content = 'vv27cq2 _1mm8p1y9 _1mm8p1y7';
export var githubLogo = 'vv27cq7 _1mm8p1y0';
export var header = _7a468({defaultClassName:'vv27cq0 _1mm8p1y4',variantClassNames:{isTop:{true:'vv27cq1'}},defaultVariants:{},compoundVariants:[]});
export var headerLogo = 'vv27cq3';
export var headerRightSection = 'vv27cq5 _1mm8p1y9 _1mm8p1y7';
export var mainHeaderLogo = 'vv27cq4';
export var searchButton = 'vv27cq8 _1mm8p1y0 _1mm8p1y7 _1mm8p1y9 _1mm8p1ya';
export var searchButtonKeyboard = 'vv27cqb _1mm8p1y7';
export var searchButtonLeftIcon = 'vv27cq9';
export var searchButtonText = 'vv27cqa';
export var sidebarToggleButton = 'vv27cq6 _1mm8p1y0';