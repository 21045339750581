exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-component-index-tsx": () => import("./../../../src/pages/component/index.tsx" /* webpackChunkName: "component---src-pages-component-index-tsx" */),
  "component---src-pages-foundation-color-color-system-tsx": () => import("./../../../src/pages/foundation/color/color-system.tsx" /* webpackChunkName: "component---src-pages-foundation-color-color-system-tsx" */),
  "component---src-pages-foundation-color-palette-tsx": () => import("./../../../src/pages/foundation/color/palette.tsx" /* webpackChunkName: "component---src-pages-foundation-color-palette-tsx" */),
  "component---src-pages-foundation-color-usage-tsx": () => import("./../../../src/pages/foundation/color/usage.tsx" /* webpackChunkName: "component---src-pages-foundation-color-usage-tsx" */),
  "component---src-pages-foundation-icon-tsx": () => import("./../../../src/pages/foundation/icon.tsx" /* webpackChunkName: "component---src-pages-foundation-icon-tsx" */),
  "component---src-pages-foundation-typography-tsx": () => import("./../../../src/pages/foundation/typography.tsx" /* webpackChunkName: "component---src-pages-foundation-typography-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-overview-get-started-tsx": () => import("./../../../src/pages/overview/get-started.tsx" /* webpackChunkName: "component---src-pages-overview-get-started-tsx" */),
  "component---src-pages-overview-index-tsx": () => import("./../../../src/pages/overview/index.tsx" /* webpackChunkName: "component---src-pages-overview-index-tsx" */),
  "component---src-pages-overview-principle-tsx": () => import("./../../../src/pages/overview/principle.tsx" /* webpackChunkName: "component---src-pages-overview-principle-tsx" */),
  "component---src-pages-overview-progress-board-tsx": () => import("./../../../src/pages/overview/progress-board.tsx" /* webpackChunkName: "component---src-pages-overview-progress-board-tsx" */),
  "component---src-pages-primitive-index-tsx": () => import("./../../../src/pages/primitive/index.tsx" /* webpackChunkName: "component---src-pages-primitive-index-tsx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-action-sheet-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/action-sheet/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-action-sheet-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-actionable-callout-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/actionable-callout/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-actionable-callout-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-actionable-inline-alert-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/actionable-inline-alert/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-actionable-inline-alert-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-alert-dialog-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/alert-dialog/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-alert-dialog-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-avatar-group-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/avatar-group/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-avatar-group-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-avatar-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/avatar/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-avatar-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-bottom-sheet-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/bottom-sheet/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-bottom-sheet-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-box-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/box-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-box-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-box-toggle-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/box-toggle-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-box-toggle-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-callout-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/callout/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-callout-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-capsule-toggle-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/capsule-toggle-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-capsule-toggle-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-checkbox-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/checkbox/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-checkbox-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-filter-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-filter/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-filter-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-radio-group-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-radio-group/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-radio-group-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-toggle-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-toggle-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-chip-toggle-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-dismissable-callout-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/dismissable-callout/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-dismissable-callout-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-dismissable-inline-alert-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/dismissable-inline-alert/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-dismissable-inline-alert-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-extended-floating-action-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/extended-floating-action-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-extended-floating-action-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-floating-action-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/floating-action-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-floating-action-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-help-bubble-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/help-bubble/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-help-bubble-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-inline-alert-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/inline-alert/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-inline-alert-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-menu-floating-action-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/menu-floating-action-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-menu-floating-action-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-multiline-text-field-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/multiline-text-field/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-multiline-text-field-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-radio-group-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/radio-group/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-radio-group-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-range-slider-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/range-slider/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-range-slider-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-select-box-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/select-box/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-select-box-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-slider-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/slider/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-slider-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-snackbar-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/snackbar/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-snackbar-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-spinner-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/spinner/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-spinner-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-switch-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/switch/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-switch-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-tabs-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/tabs/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-tabs-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-text-button-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/text-button/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-text-button-style-mdx" */),
  "component---src-templates-component-style-doc-tsx-content-file-path-content-component-text-field-style-mdx": () => import("./../../../src/templates/ComponentStyleDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/text-field/style.mdx" /* webpackChunkName: "component---src-templates-component-style-doc-tsx-content-file-path-content-component-text-field-style-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-action-sheet-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/action-sheet/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-action-sheet-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-actionable-callout-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/actionable-callout/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-actionable-callout-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-actionable-inline-alert-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/actionable-inline-alert/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-actionable-inline-alert-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-alert-dialog-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/alert-dialog/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-alert-dialog-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-avatar-group-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/avatar-group/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-avatar-group-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-avatar-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/avatar/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-avatar-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-bottom-sheet-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/bottom-sheet/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-bottom-sheet-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-box-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/box-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-box-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-box-toggle-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/box-toggle-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-box-toggle-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-callout-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/callout/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-callout-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-capsule-toggle-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/capsule-toggle-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-capsule-toggle-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-checkbox-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/checkbox/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-checkbox-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-filter-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-filter/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-filter-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-radio-group-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-radio-group/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-radio-group-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-toggle-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/chip-toggle-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-chip-toggle-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-dismissable-callout-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/dismissable-callout/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-dismissable-callout-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-dismissable-inline-alert-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/dismissable-inline-alert/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-dismissable-inline-alert-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-extended-floating-action-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/extended-floating-action-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-extended-floating-action-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-floating-action-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/floating-action-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-floating-action-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-help-bubble-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/help-bubble/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-help-bubble-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-inline-alert-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/inline-alert/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-inline-alert-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-menu-floating-action-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/menu-floating-action-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-menu-floating-action-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-multiline-text-field-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/multiline-text-field/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-multiline-text-field-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-radio-group-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/radio-group/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-radio-group-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-range-slider-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/range-slider/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-range-slider-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-select-box-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/select-box/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-select-box-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-slider-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/slider/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-slider-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-snackbar-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/snackbar/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-snackbar-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-spinner-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/spinner/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-spinner-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-switch-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/switch/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-switch-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-tabs-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/tabs/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-tabs-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-text-button-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/text-button/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-text-button-usage-mdx" */),
  "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-text-field-usage-mdx": () => import("./../../../src/templates/ComponentUsageDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/component/text-field/usage.mdx" /* webpackChunkName: "component---src-templates-component-usage-doc-tsx-content-file-path-content-component-text-field-usage-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-avatar-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/avatar/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-avatar-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-button-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/button/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-button-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-checkbox-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/checkbox/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-checkbox-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-dialog-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/dialog/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-dialog-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-popover-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/popover/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-popover-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-radio-group-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/radio-group/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-radio-group-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-slider-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/slider/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-slider-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-snackbar-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/snackbar/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-snackbar-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-tabs-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/tabs/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-tabs-primitive-mdx" */),
  "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-text-field-primitive-mdx": () => import("./../../../src/templates/PrimitiveDoc.tsx?__contentFilePath=/home/runner/work/seed-design/seed-design/docs/content/primitive/text-field/primitive.mdx" /* webpackChunkName: "component---src-templates-primitive-doc-tsx-content-file-path-content-primitive-text-field-primitive-mdx" */)
}

