export const onPrimary = 'var(--seed-semantic-color-on-primary)';
export const primary = 'var(--seed-semantic-color-primary)';
export const primaryLow = 'var(--seed-semantic-color-primary-low)';
export const secondary = 'var(--seed-semantic-color-secondary)';
export const secondaryLow = 'var(--seed-semantic-color-secondary-low)';
export const success = 'var(--seed-semantic-color-success)';
export const successLow = 'var(--seed-semantic-color-success-low)';
export const warning = 'var(--seed-semantic-color-warning)';
export const warningLow = 'var(--seed-semantic-color-warning-low)';
export const danger = 'var(--seed-semantic-color-danger)';
export const dangerLow = 'var(--seed-semantic-color-danger-low)';
export const overlayLow = 'var(--seed-semantic-color-overlay-low)';
export const overlayDim = 'var(--seed-semantic-color-overlay-dim)';
export const paperSheet = 'var(--seed-semantic-color-paper-sheet)';
export const paperDialog = 'var(--seed-semantic-color-paper-dialog)';
export const paperFloating = 'var(--seed-semantic-color-paper-floating)';
export const paperContents = 'var(--seed-semantic-color-paper-contents)';
export const paperDefault = 'var(--seed-semantic-color-paper-default)';
export const paperBackground = 'var(--seed-semantic-color-paper-background)';
export const paperAccent = 'var(--seed-semantic-color-paper-accent)';
export const primaryHover = 'var(--seed-semantic-color-primary-hover)';
export const primaryPressed = 'var(--seed-semantic-color-primary-pressed)';
export const primaryLowHover = 'var(--seed-semantic-color-primary-low-hover)';
export const primaryLowActive = 'var(--seed-semantic-color-primary-low-active)';
export const primaryLowPressed = 'var(--seed-semantic-color-primary-low-pressed)';
export const grayHover = 'var(--seed-semantic-color-gray-hover)';
export const grayPressed = 'var(--seed-semantic-color-gray-pressed)';
export const onPrimaryOverlay50 = 'var(--seed-semantic-color-on-primary-overlay-50)';
export const onPrimaryOverlay200 = 'var(--seed-semantic-color-on-primary-overlay-200)';
export const onPrimaryLowOverlay50 = 'var(--seed-semantic-color-on-primary-low-overlay-50)';
export const onPrimaryLowOverlay100 = 'var(--seed-semantic-color-on-primary-low-overlay-100)';
export const onPrimaryLowOverlay200 = 'var(--seed-semantic-color-on-primary-low-overlay-200)';
export const onGrayOverlay50 = 'var(--seed-semantic-color-on-gray-overlay-50)';
export const onGrayOverlay100 = 'var(--seed-semantic-color-on-gray-overlay-100)';
export const textSelection = 'var(--seed-semantic-color-text-selection)';
export const divider1 = 'var(--seed-semantic-color-divider-1)';
export const divider2 = 'var(--seed-semantic-color-divider-2)';
export const divider3 = 'var(--seed-semantic-color-divider-3)';
export const accent = 'var(--seed-semantic-color-accent)';
export const inkText = 'var(--seed-semantic-color-ink-text)';
export const inkTextLow = 'var(--seed-semantic-color-ink-text-low)';
export const grayActive = 'var(--seed-semantic-color-gray-active)';